.logo img{
  margin-left: 20px;
  width: 110px;
}

.sidebar{
  position: fixed;
  display: flex;
  top: 0;
  flex-direction: column;
  align-items: flex-start;
  width: 240px;
  height: 100%;
  padding: 40px 10px 30px 0;
  border-right: 5px solid black;
  z-index: 1000;
}

.sidebar-header{
  width: 100%;
}

nav{
  display: flex;
  bottom: 120px;
  flex-direction: column;
  background: transparent;
  padding: 0;
  margin: 60px 0;
}

nav a{
  font-size: 50px;
  margin: 16px 20px;
  text-decoration: none;
  flex-direction: column;
  background: none;
  height: auto;
}

nav a.active{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  border: 5px solid black;
  border-radius: 100vw;
}

.menu{
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0px;
  background: #FDFDFC;
  justify-content: center;
  border-top: 5px solid black;
  padding: 2rem 0;
  z-index: 99;
}

.menu a{
  margin: 0 5px;
}

@media(max-width: 1280px){
  nav a{
    margin: 12px 20px;
  }

  nav{
    margin: 30px 0;
  }
}

@media(max-width: 1024px){
  nav a{
    margin: 4px 20px;
  }
}

@media(max-width: 768px){
  .sidebar{
    display: none;
  }
}
