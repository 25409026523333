@font-face {
  font-family: 'Space Grotesk Bold';
  src: url(/public/Fonts/SpaceGrotesk-Bold.woff2) format('woff2');
  font-weight: bold;
}

@font-face{
  font-family: 'Space Grotesk Medium';
  src: url(/public/Fonts/SpaceGrotesk-Medium.woff2) format('woff2');
  font-weight: normal;
}

@font-face {
  font-family: 'Space Grotesk Regular';
  src: url(/public/Fonts/SpaceGrotesk-Regular.woff2) format('woff2');
  font-weight: bolder;
}

*{
  background-color: #fdfdfc;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Space Grotesk Medium", sans-serif;
  cursor: url(/public/Cursor.svg), auto;
}

::selection{
  background: black;
  color: #fdfdfc;
}

::-webkit-scrollbar{
  width: 10px;
}

::-webkit-scrollbar-track{
  background-color: #fdfdfc;
}

::-webkit-scrollbar-thumb{
  background: black;
}

a{
  color: black;
  text-decoration: none;
  transition: opacity cubic-bezier(0,.68,0,1.01) 1s;
}

a:hover{
  opacity: 75%;
}

p{
  font-size: 20px;
  background: none;
}

.header-placeholder{
  height: 0px;
}

.divider-big{
  height: 2em;
  visibility: hidden;
}

.divider-mid{
  height: 1em;
  visibility: hidden;
}

.divider-small{
  height: .5em;
  visibility: hidden;
}

#link{
  text-decoration: underline;
}

.video-embed{
  display: block;
  position: relative;
  padding-bottom: 44.5%;
  margin-top: 20px;
  margin-right: 20px;
  background: none;
}

.video-embed iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  background: none;
}

.video-embed-sqr{
  position: relative;
  padding-bottom: 44.5%;
  margin-top: 20px;
  background: none;
}

.video-embed-sqr iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 44.5%;
  height: 100%;
  background: none;
}

.project-content{
  margin-left: 260px;
}

.container-project{
  font-size: 60px;
}

h1{
  font-size: 60px;
  background: none;
}

h1::after{
  content: '';
  width: 10px;
  height: 5px;
  background-color: currentColor;
  display: block;
  margin: 20px 0;
}

#description{
  font-size: 30px;
  background: none;
  max-width: 60%;
}

a8{
  display: flex;
  font-size: 30px;
  background: none;
}

a1{
  display: block;
  font-size: 30px;
  background: none;
  margin: auto auto;
  margin-bottom: 40px;
}

@media(max-width: 1280px){
  .video-embed, .video-embed-sqr{
    padding-bottom: 56.25%;
  }

  .video-embed iframe{
    width: 100%;
  }

  .video-embed-sqr iframe{
    width: 56.25%;
  }
}

@media(max-width: 768px){
  h1{
    font-size: 35px;
  }

  a8,a1,#description{
    font-size: 23px;
  }
  
  .project-content{
    margin-left: 20px;
  }
  
  .header-placeholder{
    height: 120px;
  }

  a.active{
    height: 100px;
    display: flex;
    align-items: center;
    border: 4px solid black;
    border-radius: 100vw;
  }

  .video-embed, .video-embed-sqr{
    margin-top: 140px;
    margin-right: 20px;
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
  }

  .video-embed-sqr{
    position: relative;
    padding-bottom: 100%;
    overflow: hidden;
  }
     
  .video-embed-sqr iframe, .video-embed-sqr object, .video-embed-sqr embed, .video-embed object, .video-embed iframe, .video-embed embed{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media(max-width: 560px){
  #description{
    max-width: 90%;
  }
}