#error{
    position: absolute;
    display: flex;
    font-size: 120px;
    margin-left: 240px;
    justify-content: center;
    text-align: center;
    top: 40%;
    right: 0;
    left: 0;
}

@media(max-width: 960px){
    #error{
        top: 20%;
    }
}

@media(max-width: 768px){
    #error{
        margin-left: 0px;
        top: 30%;
    }
}
