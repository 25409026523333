.container-about{
    margin-left: 260px;
    display: flex;
}

.container-about p{
    max-width: 900px;
    font-size: 30px;
    margin: 0 40px;
    margin-bottom: 30px;
}

#hello{
    font-size: 110px;
    padding-top: 20px;
}

.avatar{
    max-width: 540px;
    object-fit: contain;
}

@media(max-width: 1280px){
    .avatar{
        max-width: 300px;
    }

    .container-about p{
        font-size: 20px;
        margin-bottom: 25px;
    }

    #hello{
        font-size: 100px;
    }
}

@media(max-width: 960px){
    .avatar{
        max-width: 260px;
    }

    #hello{
        font-size: 100px;
    }
}

@media(max-width: 860px){
    .avatar{
        max-width: 220px;
    }

    .container-about p{
        font-size: 15px;
    }

    #hello{
        font-size: 90px;
    }
}

@media(max-width: 768px){
    .container-about{
        padding-left: 0;
        flex-direction: column;
        margin-left: 0;
    }
    
    .container-about p{
        font-size: 23px;
        margin: 50px auto;
        max-width: 93%;
    }

    .avatar{
        display: block;
        margin: 0 auto;
        max-width: 93%;
        padding-top: 160px;
    }

    #hello{
        font-size: 90px;
        padding-top: 0px;
    }
}

@media(max-width: 360px){
    #hello{
        font-size: 90px;
    }
}