.container-play{
    margin-left: 240px;
}

.play-wrapper{
    width: 100%;
    background: none;
}

.play-wrapper img{
    width: 33.3333%;
    margin-bottom: -5px;
}

@media(max-width: 1280px){
    .play-wrapper img{
        width: 50%;
    }
}

@media(max-width: 768px){
   .play-wrapper img{
        width: 100%;
    }

    .container-play{
        margin-left: 0px;
    }
}