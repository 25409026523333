#close{
    position: fixed;
    display: flex;
    bottom: 40px;
    right: 15px;
    font-size: 30px;
    font-family: 'Space Grotesk Bold', sans-serif;
    background: #fdfdfc;
    border: 5px solid black;
    border-radius: 100vw;
    padding: 10px;
    letter-spacing: -1px;
}

@media(max-width: 500px){
    #close{
        display: none;
    }
}