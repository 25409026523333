ul{
    display: flex;
    position: fixed;
    flex-direction: column;
    z-index: 98;
    background:#fdfdfc;
    justify-content: center;
    align-items: center;
    list-style: none;
    font-size: 60px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

li a, li{
    display: flex;
    text-decoration: none;
}

li{
    font-size: 70px;
    margin: 16px 20px;
    text-decoration: none;
    flex-direction: column;
    font-family: "Space Grotesk Regular", sans-serif;
    background: none;
    height: auto;
}

li a.active{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    border: 5px solid black;
    border-radius: 100vw;
}

.mobSocialsMenu{
    height: 100px;
}

.socialIcons{
    background: none;
    flex-direction: row;
    margin: 15px;
    margin-top: 30px;
}

.mobNavigation{
    position: fixed;
    top: 0;
    background-color:#fdfdfc;
    width: 100%;
    height: 125px;
    font-size: 30px;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 98;
    border-bottom: 5px solid black;
}

.logo img{
    position: fixed;
    width: 100px;
    left: 20px;
    top: 10px;
    background: none;
}

.Hamburguer{
    height: 90px;
    width: 70px;
    background: transparent;
    z-index: 99;
    display: flex;
    position: fixed;
    right: 20px;
    top: 15px;
    cursor: pointer;
}

.Hamburguer:hover{
    opacity: 75%;
}

@media(max-width: 768px){
    .mobNavigation, .mobSocialsMenu{
        display: flex;
        bottom: 5%;
    }

    .socialIcons{
        margin: 28px 12px;
    }

    li{
        font-size: 50px;
    }
    
}

@media(max-width: 360px){
    .socialIcons{
        margin: 48px 6px;
    }

    li{
        margin: 12px 20px;
        font-family: "Space Grotesk Medium", sans-serif;
    }
}
